<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Definisci quotazione</span>
            <br><br>
            <hr>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_pulsante_abilita_enabled"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <div class="row justify-center">

            <div class="col-md-6 col-12 q-pr-md" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{quotazione.stato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-md-4 col-12 q-pr-md" align="left">
                <h5 class="q-mt-sm q-pt-sm">Dati del Contratto</h5>

                <q-select outlined
                    error-message="Il settore deve essere valorizzato"
                    v-model="settore"
                    :options="elenco_settori"
                    label="Settore Prodotto"
                    name="settore_prodotto"
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    @input="onSelectSettore()"
                    style="margin: 4px;" />

                <q-select outlined

                    error-message="Il prodotto deve essere valorizzato"
                    v-model="prodotto_da_catalogo"
                    :options="elenco_prodotti"
                    label="Prodotto a catalogo"
                    name="prodotto_catalogo"
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    @input="onSelectProdotto()"
                    style="margin: 4px;" />

                <q-input outlined
                    v-model="compagnia"
                    label="Compagnia"
                    readonly
                    style="margin: 4px;" />

                <q-input outlined
                    v-model="tariffa"
                    label="Tariffa"
                    readonly
                    style="margin: 4px;" />

                <q-input outlined
                    v-model="fornitore"
                    label="Fornitore"
                    readonly
                    style="margin: 4px;" />

                <q-select outlined
                    name="tipo_rateazione"
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    error-message="La rateazione deve essere valorizzata"
                    v-model="rata"
                    :options="rate_premio"
                    style="margin: 4px;"
                    label="Tipo di Rateazione del premio" />

                <q-select outlined
                    v-show="DurataAnniIsVisible()"
                    error-message="La durata del contratto deve essere valorizzata"
                    v-model="durata_anni"
                    :options="durata_contratto"
                    style="margin: 4px;"
                    name="durata_contratto"
                    virtual-scroll-slice-size="0"
                    virtual-scroll-item-size="0"
                    label="Anni durata Premio Unico" >
                    <template v-slot:append>
                        Anni
                    </template>
                </q-select>

                <q-input outlined
                    error-message="Il premio del preventivo deve essere inserito"
                    v-model="premio"
                    :label="label_premio"
                    style="margin: 4px;"
                    type="number"
                    name="premio_annuale"
                >
                    <template v-slot:append>
                        Euro
                    </template>
                </q-input>

                <q-input outlined
                    v-show="PremioSemestraleIsVisible()"
                    error-message="Il premio del preventivo deve essere inserito"
                    v-model="premio_semestrale"
                    label="Premio SEMESTRALE del Preventivo"
                    style="margin: 4px;"
                    type="number"
                    name="premio_semestrale"
                >
                    <template v-slot:append>
                        Euro
                    </template>
                </q-input>
                <br>

            </div>

        </div>

        <br><br>
        <hr>
        <div class="row" align="center">
            <div class="col-12 col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-6" align="center" :disabled="!is_pulsante_abilita_enabled">
                <QQButton label="DEFINISCI QUOTAZIONE" color="blue-grey" icon="mdi-content-save-outline" size="md" icon_align="right"
                    @click.native="onRegistraDefinizione"
                />
            </div>
        </div>

        <br><br><br><br>

    </div>

</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy.js";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";

    //import store from "@/store";

    export default {
        name: "DefinisciQuotazione",
        data() {
            return {
                quotazione: {},
                elenco_compagnie: [],
                elenco_fornitori: [],
                elenco_tariffe: [],
                elenco_prodotti: [],
                elenco_completo_prodotti: [],
                compagnia: "",
                fornitore: "",
                prodotto_da_catalogo: "",
                premio: 0,
                premio_semestrale: 0,
                durata_anni: 1,
                tariffa: "",
                rata: "",
                is_pulsante_abilita_enabled: true,
                settore: "",
                rate_premio: [
                    { label: "Può essere solo annuale", value: "annuale" },
                    { label: "Può essere sia annuale che semestrale", value: "semestrale" },
                    { label: "Si tratta di un premio unico", value: "unico" }
                ],
                elenco_settori: [
                    { label: "Persone e Famiglia", value: 2 },
                    { label: "Professionisti", value: 3 },
                    { label: "Aziende", value: 4 },
                    { label: "Fidejussioni, Cauzioni e Asseverazioni", value: 5 },
                    { label: "Altri Rami", value: 6 },
                    { label: "Corpi e Natanti", value: 7 },
                    { label: "Retail", value: 8 }
                ],
                durata_contratto: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            label_premio() {
                switch(this.rata.value) {
                    case "annuale": return "Premio del Preventivo";
                    case "semestrale": return "Premio ANNUALE del preventivo";
                    case "unico": return "Premio UNICO del preventivo";
                }

                return "Premio del Preventivo";
            },
            isValid() {
                if ((this.compagnia === "") ||
                    (this.fornitore === '') ||
                    (this.tariffa === '') ||
                    (parseInt(this.premio) === 0)) return false;

                return true;
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                //fetchElencoCompagnie: "gestioneCatalogoProdotti/fetchElencoCompagnie",
                fetchCatalogoProdottiByIDSettore: "gestioneCatalogoProdotti/fetchCatalogoProdottiByIDSettore",
                fetchElencoTariffe: "gestioneCatalogoProdotti/fetchElencoTariffe",
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo",
                fetchElencoEmittenti: "gestioneCatalogoProdotti/fetchElencoEmittenti",
                fetchIDSettore: "gestioneCatalogoProdotti/fetchIDSettore",
                DefinisciQuotazione: "gestioneQuotazioni/DefinisciQuotazione"
            }),
            PremioSemestraleIsVisible() {
                if (this.rata.value === "semestrale") return true;
                this.premio_semestrale = 0;
                return false;
            },
            DurataAnniIsVisible() {
                if (this.rata.value === "unico") return true;
                this.durata_anni = 1;
                return false;
            },
            GetCodiceTariffa() {
                switch(this.quotazione.id_prodotto) {
                    case "spese_veterinarie": return "Veterinaria";
                    case "tutela_reddito": return "TutelaReddito";
                    case "multirischio_casa": return "MultirischiCasa";
                    case "virus": return "3Virus";
                    case "rcp_tecnico": return "RCTecnico";
                    case "rcp_medico": return "RCMedico";
                    case "rcp_sanitario": return "RCSanitari";
                    case "multirischio_artigiano": return "MultirischiArtigiano";
                    case "multirischio_commercio": return "MultirischiCommercio";
                    case "globale_fabbricato": return "GlobaleFabbricato";
                    case "infortuni": return "Infortuni";
                }

                return "TaylorMade";
            },
            CapitalizeString(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            async onSelectSettore() {
                var settore = this.settore.value;
                console.log("settore selezionato:",settore);
                await this.AggiornaElencoProdotti(settore);
            },
            onSelectProdotto() {
                //console.log("value:",this.prodotto_da_catalogo.value);
                //let codici = this.prodotto_da_catalogo.value.split(" / ");
                //let codice_esteso = codici[1];
                let codici = this.prodotto_da_catalogo.value.split(" - ");
                let codice_esteso = codici[3];

                if (codice_esteso.length === 4) {
                    let start = codice_esteso.substr(0,1);
                    let cod   = "0"+codice_esteso.substr(1,codice_esteso.length - 1);
                    codice_esteso = start + cod;
                    //console.log("Ci siamo:",codice_esteso);
                }

                //console.log("elenco fornitori:",this.elenco_fornitori);

                var cerca_fornitore = this.elenco_fornitori.find(f => {
                    return f.label.includes(codice_esteso);
                });

                if (commonLib.isEmpty(cerca_fornitore)) {
                    alert("Fornitore non definito");
                    this.tariffa   = "";
                    this.fornitore = "";
                    return;
                }
                this.fornitore = cerca_fornitore.value;

                this.tariffa = (this.quotazione.id_prodotto + this.prodotto_da_catalogo.nome_breve).toLowerCase();
                if (this.tariffa != "") this.tariffa = this.tariffa.replace("_","");
                this.compagnia = this.prodotto_da_catalogo.nome_breve;

                //console.log("fornitore identificato,prodotti:",cerca_fornitore.value,this.prodotto_da_catalogo);

                //var compagnia = this.CapitalizeString(this.compagnia.value);
                //this.tariffa = this.GetCodiceTariffa() + compagnia;
                //this.fornitore = "";
            },
            async AggiornaElencoProdotti(settore) {
                var codice_settore = "";
                var record_settore = this.elenco_settori.filter(element => {
                    return element.value === settore;
                });
                this.settore = record_settore[0];

                //console.log("elenco completo prodotti:", this.elenco_completo_prodotti);

                switch(parseInt(settore)) {
                    case 2: codice_settore = "B"; break;
                    case 3: codice_settore = "C"; break;
                    case 4: codice_settore = "D"; break;
                    case 5: codice_settore = "E"; break;
                    case 6: codice_settore = "F"; break;
                    case 7: codice_settore = "G"; break;
                    case 8: codice_settore = "R"; break;
                }

                //console.log("settore:",this.settore.value,codice_settore);
                this.elenco_prodotti = this.elenco_completo_prodotti.filter(prodotto => {
                    return prodotto.value.trim().charAt(0) === codice_settore;
                });
                //console.log("nuovo elenco prodotti:",this.elenco_prodotti);
            },
        //    onSelectTariffa() {
        //        this.fornitore = this.tariffa.fornitore;
        //    },
            setTariffa(value) {
                this.tariffa = value;
            },
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            async onRegistraDefinizione() {
                if (!this.isValid) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi inserire la Compagnia, selezionare la tariffa e inserire un premio diverso da zero prima di continuare'
                    });

                    return;
                }

                this.is_pulsante_abilita_enabled = false;

                let dati = {
                    guid_preventivo: this.quotazione.guid_preventivo,
                    id_quotazione: this.quotazione.idquotazione,
                    _compagnia: this.compagnia,
                    _tariffa: this.tariffa,
                    _fornitore: this.fornitore,
                    _emittente: this.fornitore,
                    _settore: this.settore.value,
                    _premio: this.premio,
                    _premio_semestrale: this.premio_semestrale,
                    _tipo_rateazione: this.rata.value,
                    _durata_anni: this.durata_anni
                };

                await this.DefinisciQuotazione(dati);

                this.is_pulsante_abilita_enabled = true;

                // Reindirizza sul menu di gestione della quotazione selezionata
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            inCostruzione() {
                alert("IN COSTRUZIONE");
            },

        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this. is_pulsante_abilita_enabled = true;
            // this.elenco_compagnie = await this.fetchElencoCompagnie(false);
            // this.elenco_compagnie =
            //console.log("id_prodotto:",this.quotazione.id_prodotto);

            this.elenco_completo_prodotti = await this.fetchCatalogoProdottiByIDSettore({ prodotto_id: "", ValueAsLabel: false});

            //console.log("elenco completo prodotti:",this.elenco_completo_prodotti);

            var settore = await this.fetchIDSettore(this.quotazione.id_prodotto);
            await this.AggiornaElencoProdotti(settore);
            this.elenco_fornitori = await this.fetchElencoEmittenti();
            this.elenco_tariffe   = await this.fetchElencoTariffe();

            //console.log("elenco_fornitori:",this.elenco_fornitori);

            await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);

            this.compagnia = "";
            this.fornitore = "",
            this.tariffa   = "";
            this.premio    = 0;
            this.rata      = "";
            this.premio_semestrale = 0;
        },
        async activated() {
            this.quotazione       = await this.fetchDatiQuotazione(this.idquotazione);
            // this.elenco_compagnie = await this.fetchElencoCompagnie(false);
            this.elenco_compagnie = await this.fetchCatalogoProdottiByIDSettore(this.quotazione.id_prodotto,false);
            this.elenco_fornitori = await this.fetchElencoEmittenti();
            this.elenco_tariffe   = await this.fetchElencoTariffe();

            await this.fetchDatiPreventivo(this.quotazione.guid_preventivo);

            this.compagnia = "";
            this.fornitore = "",
            this.tariffa   = "";
            this.premio    = 0;
            this.rata      = "";
            this.premio_semestrale = 0;
        }
    }
</script>
